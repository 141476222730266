import React from "react";
import {
  WebsiteSchema,
  SiteNavigationElementSchema,
  FoodEstablishmentSchema,
  MessageDisplay,
  Navigation,
  EmailCapture,
  Footer,
  SocialMedia,
  SiteMap,
  CopyrightLabel,
  OutboundLink,
  FishermanBanner,
} from "@bluefin/components";
import { graphql, StaticQuery } from "gatsby";

import { getPageHeroImage } from "../utils/utils";
import "semantic-ui-less/semantic.less";
import "../style/WebsiteLayout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        fishermanBusiness {
          name
          type
          subtypes
          logo
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
          gatsbyLogo {
            childImageSharp {
              fixed(height: 60) {
                base64
                width
                height
                src
                srcSet
              }
            }
          }
          primaryLocation {
            _id
          }
          _id
          socialMedia {
            link
            type
          }
        }
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            title
            pageType
            url
            navigationPriority
            groupName
            props
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(title: { eq: "Home" }) {
          components {
            fastId
            order
            files {
              file
              gatsbyFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    src
                    srcSet
                    aspectRatio
                    base64
                  }
                }
              }
            }
          }
        }
        fishermanWebsiteComponent(layout: { name: { eq: "@" } }) {
          data {
            subject
            content
            modalDelay
            bannerBackgroundColor
          }
        }
      }
    `}
    render={(data) => {
      const {
        fishermanBusiness,
        allFishermanBusinessWebsitePage,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
        fishermanWebsiteComponent,
      } = data;

      return (
        <React.Fragment>
          <WebsiteSchema
            name={fishermanBusiness.name}
            url={"https://www.mikesfisherman.com"}
          />
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://www.mikesfisherman.com"}
          />
          <FoodEstablishmentSchema
            name={fishermanBusiness.name}
            siteUrl={"https://www.mikesfisherman.com"}
            businessType={fishermanBusiness.type}
            businessSubtypes={fishermanBusiness.subtypes}
            logo={fishermanBusiness.logo}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getPageHeroImage({
              options: fishermanBusinessWebsitePage.components,
            })}
          />
          <div className={"site-container"}>
            <div>
              <MessageDisplay
                subject={fishermanWebsiteComponent.data.subject}
                content={fishermanWebsiteComponent.data.content}
                businessName={fishermanBusiness.name}
                modalDelay={fishermanWebsiteComponent.data.modalDelay}
                bannerBackgroundColor={
                  fishermanWebsiteComponent.data.bannerBackgroundColor
                }
              />
              <Navigation
                links={allFishermanBusinessWebsitePage.nodes}
                bordered={false}
                centerMobileNavItems={true}
                fixed={false}
                header={fishermanBusiness.name}
                logo={fishermanBusiness.gatsbyLogo.childImageSharp}
                linksAs={"text"}
                primaryContentCentered={false}
              />
            </div>
            <div className={"page-container"}>{children}</div>
            <EmailCapture
              locationId={fishermanBusiness.primaryLocation._id}
              businessId={fishermanBusiness._id}
              businessType={"restaurant"}
              delay={false}
              closeDelay={3}
              type={"modal"}
            />
            <Footer
              backgroundColor={"primary"}
              horizontalAlign={"center"}
              verticalAlign={"middle"}
              columns={[
                <div />,
                <div>
                  <SocialMedia
                    socialMediaValues={fishermanBusiness.socialMedia}
                    buttonType={"circle"}
                    centerHeader={false}
                    colors={"secondary"}
                    groupButtons={false}
                    groupVertically={false}
                    header={""}
                    inverted={false}
                    labelPosition={"free"}
                    showLabels={false}
                  />
                  <SiteMap
                    links={allFishermanBusinessWebsitePage.nodes}
                    horizontal={true}
                    bulleted={false}
                  />
                  <EmailCapture
                    locationId={fishermanBusiness.primaryLocation._id}
                    businessId={fishermanBusiness._id}
                    businessType={"restaurant"}
                    delay={false}
                    type={"footer"}
                  />
                  <CopyrightLabel
                    company={fishermanBusiness.name}
                    phrase={"All Rights Reserved"}
                    privacyPolicyLink={
                      <OutboundLink
                        to={
                          "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                        }
                        label={"Privacy Policy"}
                        eventLabel={"copyright-privacy-policy"}
                      />
                    }
                  />
                </div>,
                <div />,
              ]}
            />
          </div>
          <FishermanBanner />
        </React.Fragment>
      );
    }}
  />
);
